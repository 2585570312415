
.btn-payment
    width 100%
    background white
    cursor pointer
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    border-radius: 4px;
    height: auto;
    transition: 0.2s ease-in-out;
    text-align: center;
    padding: 12px
    height: 100%
    @media (hover: hover) and (pointer: fine)
        &:hover
          border-color: transparent;
          box-shadow: 0 0 0 1px $color-bnn-border;
          transition: 0.2s ease-in-out;
          // border-color: $color-dark-gray;
    .text-btn-payment
        font-size 1rem
    @media medium
        .text-btn-payment
            font-size 1.2rem
    @media small
        .text-btn-payment
            font-size 0.8rem
.btn-payment.select
    box-shadow: 0 0 0 1px $color-bnn;
    border-color: transparent;
.btn-payment.has-error
     border-color: red;
